<template>
	<div class="box">
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<slot name="header" />

			<template v-for="item in formOptions">
				<template v-if="!item.hide">
					<div v-if="item.slot" :key="item.prop">
						<slot :name="item.prop" />
					</div>
					<!--复选框组和单选框组 -->
					<van-field v-else-if="['checkboxGroup', 'radio', 'checkbox'].includes(item.type) && !item.hidden" :key="item.prop" :name="item.prop" :label="item.label" :disabled="item.disabled" :readonly="item.readonly" :rules="rules[item.prop]">
						<template #input>
							<!-- 复选框 -->
							<van-checkbox-group v-if="item.type == 'checkboxGroup' || item.type == 'checkbox'" v-model="form[item.prop]" :direction="item.direction ? item.direction : 'horizontal'" :max="item.max ? item.max : item.options.length" :disabled="item.disabled" :readonly="item.readonly">
								<van-checkbox v-for="v in item.options" :key="v.name" :name="v.name" :shape="item.shape ? item.shape : 'square'" :disabled="v.disabled" :readonly="v.readonly" class="pt-4">{{ v.label }}</van-checkbox>
							</van-checkbox-group>
							<!-- 单选框 -->
							<van-radio-group v-if="item.type === 'radio'" v-model="form[item.prop]" :direction="item.direction ? item.direction : 'horizontal'" :disabled="item.disabled" :readonly="item.readonly">
								<van-radio v-for="v in item.options" :key="v.name" :name="v.name" :disabled="v.disabled" :readonly="v.readonly">{{ v.label }}</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 选择器 -->
					<van-field v-else-if="fieldType.includes(item.type) && !item.hidden" :key="item.props" readonly label-class="cell_title" value-class="cell_value" is-link clickable :name="item.prop" :label="item.label" :value="formShow[item.prop]" :placeholder="item?.placeholder ? item.placeholder : '请选择' + item.label" :rules="rules[item.prop]" @click="showPicker(item)" :disabled="item.disabled" />

					<!-- 输入框 -->
					<van-field v-else-if="!item.hidden" :key="item.prop" :label="item.label" :name="item.prop" v-model="form[item.prop]" label-class="cell_title" value-class="cell_value" class="no_link" is-link :border="border" :readonly="item.readonly" :disabled="item.disabled" :type="item.inputType" :placeholder="item?.placeholder ? item.placeholder : '请输入' + item.label" :rules="rules[item.prop]"> </van-field>
				</template>
			</template>
		</van-form>
		<!-- 日期选择器 -->
		<van-calendar v-if="activePick.type === 'calendar'" color="#2594ef" v-model="showPick" :default-date="new Date(this.form[activePick.prop])" :min-date="activePick?.minDate ? new Date(activePick.minDate) : new Date()" :max-date="activePick?.maxDate ? new Date(activePick.maxDate) : new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 200)" :title="activePick.placeholder" @confirm="onConfirm" :formatter="$base.calendarFormatter" />
		<!-- 多选 -->

		<van-popup v-else v-model="showPick" position="bottom" :close-on-click-overlay="moreSelectType.includes(activePick.type) ? false : true">
			<!-- 选择器 -->
			<van-picker v-if="activePick.type === 'picker'" show-toolbar value-key="label" :default-index="defaultIndex" :title="'请选择' + activePick.label" :columns="activePick.options" @confirm="onConfirm" @cancel="showPick = false" />
			<!-- 级联选择器-->
			<van-cascader v-if="activePick.type === 'cascader'" v-model="areaData" :title="'请选择' + activePick.label" :options="activePick.options" @finish="onFinish" @close="showPick = false" :field-names="fieldNames" active-color="#2594ef"></van-cascader>
			<!-- 时间选择器 -->
			<van-datetime-picker v-if="activePick.type === 'datetimePicker'" v-model="currentDate" :type="activePick?.dateType ? activePick.dateType : 'date'" :title="'请选择' + activePick.label" @confirm="onConfirm" @cancel="showPick = false" visible-item-count="3" :min-date="activePick.minDate ? activePick.minDate : minDate" />

			<!-- 多选 -->
			<moreSelect v-if="moreSelectType.includes(activePick.type)" :options="activePick.options" :title="activePick.label" :selectData="selectData" @submitCountry="submitCountry" @click="showPick = false" @cancel="showPick = false"></moreSelect>
		</van-popup>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Dialog, CheckboxGroup, Radio, RadioGroup, Area, Cascader, Toast } from 'vant';

import moreSelect from './moreSelect.vue';
import moment from 'moment';
export default {
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[CheckboxGroup.name]: CheckboxGroup,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Dialog.Component.name]: Dialog.Component,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Area.name]: Area,
		[Cascader.name]: Cascader,
		[Toast.name]: Toast,
		moreSelect,
	},
	model: {
		prop: 'modelValue',
	},
	props: {
		formOption: {
			type: Array,
			default: () => [],
		},
		rules: {
			type: Object,
			default: () => {},
		},
		modelValue: {
			type: Object,
			default: () => {},
		},
		border: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			fieldType: ['picker', 'calendar', 'cascader', 'moreSelect', 'selectMultiple', 'datetimePicker'],
			fieldNames: {
				value: 'code',
				text: 'name',
				children: 'children',
			},
			showPick: false,
			activePick: '',
			areaData: '',
			formShow: {}, //显示数据
			selectData: [],
			currentDate: new Date(),
			minDate: new Date(1914, 0, 1),
			defaultIndex: 0, //picker默认选择
			moreSelectType: ['moreSelect', 'selectMultiple'],
		};
	},
	computed: {
		form: {
			get() {
				// 处理传递过来的数据
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val);
			},
		},
		formOptions() {
			return this.formOption;
		},
	},
	watch: {
		form: {
			handler() {
				this.handleFormShow();
			},
			deep: true,
			immediate: true,
		},
	},

	methods: {
		showPicker(item) {
			if (item.disabled || item.readonly) return;

			this.activePick = item;
			// 默认选中
			if (item.type === 'picker') {
				const index = item.options.findIndex(v => v.name === this.form[item.prop]);
				this.defaultIndex = index;
			}
			if (item.type === 'datetimePicker' && (item.dateType == 'date' || !item?.dateType)) {
				if (this.form[item.prop]) {
					const val = new Date(this.form[item.prop]);
					this.currentDate =val;
				} else {
					this.currentDate = new Date()
				}
			}
			if (this.moreSelectType.includes(item.type)) {
				this.selectData = this.form[item.prop] ? this.form[item.prop] : [];
			}
			if (item.type === 'cascader' && this.form[item.prop]) {
				if (this.form[item.prop]?.indexOf('&') !== -1) {
					const data = this.form[item.prop].split('&');
					this.areaData = data[data.length - 1];
				} else {
					this.areaData = this.form[item.prop];
				}
			}
			this.showPick = true;
		},

		onConfirm(val) {
			if (!val) return;
			this.showPick = false;
			if (this.activePick.type === 'calendar') {
				this.form[this.activePick.prop] = `${this.$base.getDate(val)} ${this.$base.getTime(new Date())}`;
			} else if (this.activePick.type === 'datetimePicker' && (this.activePick.dateType == 'date' || !this.activePick?.dateType)) {
				this.form[this.activePick.prop] = `${this.$base.dateFormater(val)} `;
			} else {
				this.form[this.activePick.prop] = val.name;
			}
		},
		onFinish({ selectedOptions }) {
			this.showPick = false;
			let result = [];
			let str = selectedOptions[0]?.name || '';
			selectedOptions.forEach(node => {
				result.push(`${node.name}&${node.code}`);
				if (this.activePick.cascaderShow !== 'last') {
					str += node.name;
				}
			});
			if (this.activePick.cascaderShow !== 'last') {
				this.form[this.activePick.prop] = result.join('/');
			} else {
				this.form[this.activePick.prop] = result[result.length - 1]?.split('&')[1];
			}
			this.formShow[this.activePick.prop] = str;
		},
		submitCountry({ result }) {
			this.showPick = false;
			this.form[this.activePick.prop] = result;
		},
		// 获取数据
		handleData() {
			return new Promise(resolve => {
				this.$refs.form.validate().then(
					() => {
						let result = JSON.parse(JSON.stringify(this.form));
						// 处理日期
						for(let i in result){
							if(isNaN(result[i])&&!isNaN(Date.parse(result[i]))){
								result[i]=result[i].replace(/\//g, '-')
							}
						}
						resolve({ ...result });
					},
					() => {
						Toast('请填写信息');
					},
				);
			});
		},
		// 获取展示数据
		getFormShow() {
			return new Promise(resolve => {
				resolve({ ...this.formShow });
			});
		},
		resetValid() {
			this.$refs.form.resetValidation();
		},
		// 处理回显数据
		handleFormShow() {
			this.formOptions.forEach(item => {
				if (this.fieldType.includes(item.type)) {
					this.formShow[item.prop] = '';

					// 回显picker
					if (item.type === 'picker') {
						if (this.form[item.prop]) {
							const res = item.options.filter(v => v.name == this.form[item.prop]);
							if (res.length) {
								this.formShow[item.prop] = res[0].label;
							}
						}
					}
					//  回显日历
					else if (item.type === 'calendar' || item.type === 'datetimePicker') {
						if (this.form[item.prop]) {
							this.formShow[item.prop] = this.$base.getDateFormate(new Date(this.form[item.prop]));
						}
					} else if (item.type === 'cascader') {
						if (this.form[item.prop]) {
							if (item.cascaderShow !== 'last') {
								const result = this.form[item.prop].split('/');
								let str = '';
								result.forEach(v => {
									str = str + v.split('&')[0] + '/';
								});
								this.formShow[item.prop] = str.slice(0, -1);
							} else {
								const data = this.getCascaderText(this.form[item.prop], item.options, 'code');
								this.formShow[item.prop] = data.name || '';
							}
						} else {
							this.formShow[item.prop] = '';
						}
					}
					// 回显多选
					else if (this.moreSelectType.includes(item.type)) {
						const result = item.options.filter(v => this.form[item.prop].includes(v.name));
						let str = '';
						result.forEach(v => {
							str += v.label + ',';
						});
						this.formShow[item.prop] = str.slice(0, -1);
					}

					const multipleFieldList = item.rule?.filter(d => d?.validation?.indexOf('multipleFieldMinSelect') !== -1);
					if (multipleFieldList?.length > 0) {
						this.$refs.form?.validate([item.prop]);
					}

					// 解决点击确定其他弹框会跳出问题
					setTimeout(() => {
						this.activePick = item;
					}, 300);
				}
			});
		},
		// 获取级联选择器的显示文本
		getCascaderText(id, options, fieldNames) {
			let data = null;
			for (let item of options) {
				if (item[fieldNames] === id) {
					data = item;
					break;
				} else if (item.children && item.children.length > 0) {
					data = this.getCascaderText(id, item.children, fieldNames);
					if (data) {
						break;
					}
				}
			}
			return data;
		},
	},
};
</script>

<style lang="scss" scoped>
.box {
	font-size: 0.3rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: $color_1;
}
::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}
::v-deep .cell_title {
	color: $font_color_sec;
}
::v-deep .cell_value {
	color: $font_color_val;
}
.pt-4 {
	padding-top: 4px;
}

::v-deep .van-picker__confirm{
	color: $color_main;
}
</style>
